@mixin laptop{
    @media screen and (max-width:1190px) {
        @content;
    }
}

@mixin tablet{
    @media screen and (max-width:980px) {
        @content;
    }
}


@mixin mobile{
    @media screen and (max-width:500px) {
        @content;
    }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        
     }
}

// /* Fading animation */
// .fade {
//   animation-name: fade;
//   animation-duration: 1.5s;
// }

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}
// STYLES STARTS HERE.......................>
$color1:#C36AFE;
$color2:#464646;
$color3:#ffffff;
$color4:#6D6D6D;
$color5:#713C7D;
$color6:#2FAEF7;
$color7:#E2F4FF;
$color8:#1B8ED1;
$palletecolor1:#C36AFE;
$palletecolor2:#767676;
$palletecolor3:#0166A0;
$palletecolor4:#33B4FF;
$palletecolor5:#6D6D6D;
$palletecolor6:#414141;

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}

*{
    padding: 0;
    margin: 0;
    text-decoration: none;
    box-sizing: border-box;
    font-family: Poppins;
    user-select: none;
}

html{
    font-size: 62.5%;

}
.app{
    // background-color: black;
    // color: white !important;
    nav{
        color: $color3;       
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        height: 70px;
        padding: 4px 0px;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 20;

        // padding-left: 100px;
        // padding-right: 100px;
        // transition: 0.1s;
        // background-color: black;
        .navbar{
          flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1440px;
        margin: auto;
        padding-left: 100px;
        padding-right: 100px;
       

        @include mobile(){
          padding-left: 20px;
          padding-right: 20px;
        }
        }
        .nav__logo{
            // flex: 5;
            cursor: pointer;
      }
     

        .nav__menu{
            // flex: 7;
            display: flex;
            align-items: center;
            gap:12.5rem;
            
            @include tablet(){
                gap:2.5rem;
            }

            @include tablet(){
              gap:2.5rem;
          }
            // border: 1px solid green;
            #menu_items{
                display: flex;
                list-style-type: none;
                flex-direction: row;

                // border:1px solid red;
                @include tablet() {
                    display:none;
                }
                li{
                    padding: 1.2rem 3.2rem;
                    font-size: 1.6rem;
                    font-weight: 300;

                    a{  
                        white-space: nowrap;
                        position: relative;
                        cursor: pointer;
                        &::after{
                            content: '';
                            width: 0%;
                            height: 0.25rem;
                            left: 0;
                            bottom: -0.8rem;
                            position: absolute;  
                            background-color: #256FB4 ; 
                            background-color: #FFB400 ; 
                            transition: 0.5s;
                        }
                        &:hover::after{
                            width: 100%;
                            @include mobile(){
                              width: 0;
                            }
                        }
                        &:hover{
                            color: #256FB4;
                            color: #FFB400;

                        }
                    }

                    @include mobile(){
                      padding: 3rem 3.2rem;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex: 1;
                      // box-shadow: 0 40px 46px 0 rgba(0, 0, 0, 0.08);
                      border-bottom: 1px solid rgba(126, 123, 123, 0.151);
                    
                    }
                }
                button{
                  display: none;
                  @include mobile(){
                      display: block;
                      margin: auto;
                      padding: 16px 130px;
                      margin-top: 46px;
                      background-color:white ;
                      color: #256FB4 !important;
                      font-size: 16px;
                  }
                }
                @include mobile(){
                 display: none;
                }
            }

            #menu_items2{
              // display: flex;
              display: none;
              list-style-type: none;
              flex-direction: row;

              // border:1px solid red;
              @include tablet() {
                  display:none;
              }
              li{
                  padding: 1.2rem 3.2rem;
                  font-size: 1.6rem;
                  font-weight: 300;

                  a{  
                      white-space: nowrap;
                      position: relative;
                      cursor: pointer;
                      &::after{
                          content: '';
                          width: 0%;
                          height: 0.25rem;
                          left: 0;
                          bottom: -0.8rem;
                          position: absolute;  
                          background-color: $palletecolor1; 
                          transition: 0.5s;
                      }
                      &:hover::after{
                          width: 100%;
                          @include mobile(){
                            width: 0;
                          }
                      }
                      &:hover{
                          color: $palletecolor1;
                      }
                  }

                  @include mobile(){
                    padding: 3rem 3.2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    // box-shadow: 0 40px 46px 0 rgba(0, 0, 0, 0.08);
                    border-bottom: 1px solid rgba(126, 123, 123, 0.151);
                  
                  }
              }
              button{
                display: none;
                @include mobile(){
                    display: block;
                    margin: auto;
                    padding: 16px 130px;
                    margin-top: 46px;
                    background-color: #ffb400;
                    color: white !important;
                    font-size: 16px;
                }
              }
              @include mobile(){
                // align-items: center;
                transition: 0.5s ease-in-out;
                flex-direction: column;
                position: absolute;
                background-color: white;
                width: 100%;
                top: 69px;
                left: 0;
                display: none;
                min-height: 100vh;
                z-index: -1;
              }
          }
            .nav__menu--icon{
                font-size: 3rem;
                display: none;
                @include tablet(){
                    display: block;
                }
                &:visited{
                  color: $palletecolor1;
                }
            }
            &>button{
                padding: 1.2rem 3.2rem;
                border: 1px solid #256FB4;
                border-radius: 0.4rem;
                background-color:white;
                color: #256FB4;
                cursor: pointer;
                &:hover{
                  background-color:#256FB4     ;
                  background-color:#FFB400     ;
                  border:1px solid #FFB400;
                  
                  color:white;
                }
                &:active{
                  background-color: #256FB4 ;
                }
                white-space: nowrap;

                @include tablet(){
                  display: none;
                }
            }
        }
    }
    .sticky{
      box-shadow: 0 40px 46px 0 rgba(0, 0, 0, 0.08);
      top: 0;
      color: $palletecolor2;
      background-color: white;

      @include mobile(){
        box-shadow: 0 40px 46px 0 rgba(0, 0, 0, 0.08);
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      }
      // z-index: 2;
      .nav__menu button{
        color: #256FB4;
        border: 1px solid #256FB4;
      }

      // #menu_items{
      //   li{
      //     a{

      //       &::after{
      //         background-color: #256FB4;
      //       }
            
      //       &:hover{
      //         color: #256FB4;
      //     }

        
            
      //     }
      //   }
      // }                                                                                                   
    }
    .emptydiv{
      height: 142px;
      //  border: 1px solid red;
      background-color: #D5EEFC;
    }
  
    .headerContainer{
        color: $color3;
        background-image: url("./assets/new/herobg.jpg");
        background-size: cover;
        background-position: center;
        min-height: 72.7rem;
        @include mobile(){
          min-height: 62.7rem;
        }
       header{
        max-width: 1440px;
        padding: 0 10rem;
        margin: auto;
        @include laptop(){
            padding: 0 5rem;
        }
    
        .heroSection{
            margin: auto;
            padding-top: 19.9rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 107.7rem;
            @include mobile(){
              padding-top: 10.9rem;
            }
            // border: 1px solid red;
             h1{
                    
              font-size: 4.8rem;
              font-weight: 600;
              line-height: 110%;
              text-align: center;

              @include mobile(){
                font-size: 2.3rem;
                line-height: 140%;
              }
              span{
                color: $palletecolor1;
              }
              }
              p{
                  margin-top: 1.9rem;
                  font-size: 1.6rem;
                  text-align: center;
                  font-weight: 400;
                  max-width: 81.8rem;
                  @include mobile(){
                    font-size: 1.3rem;
                  }
              }
              button{
                  font-size: 1.6rem;
                  margin-top: 4.8rem;
                  padding: 1.2rem 3.2rem;
                  border: 1px solid white;
                  border-radius: 0.6rem;
                  background-color: transparent;
                  color:$color3 ;
                  display: flex;
                  align-items: center;
                  gap: 1rem;
                  cursor: pointer;
                  &:hover{
                    background-color: #FFB400;
                    color: $color3;
                    border-color: #FFB400;
                    
                  }
                  &:active{
                    background-color: #256FB4;
                    transform: scale(1.2);
                    
                  }
              }

              .icon_text{
                display: flex;
                gap: 28px;
                align-items: center;

                @include mobile(){
                  
                  gap: 30px;
                }
              }
              .icon_text p{
                font-size: 20px;
                font-weight: 400;

                @include mobile(){
                  font-size: 10px;
                }
              }
              .icon_cont{
                display: flex;
                align-items: center;
                gap: 12px;
              }
              .icon_cont span img{
                width: 20px;
              }
    
        }
       }
    }

    .aboutus{
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: auto;
      margin-bottom: 40px;
      padding-top: 13rem;
      max-width: 1140px;

      // border: 1px solid red;                                     
      gap: 4.5rem;
      @include laptop(){
        padding-left: 13rem;
        padding-right: 13rem;
      }
      @include mobile(){
        flex-direction: column;
        gap: 0;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 4rem;  
       
      }
      .aboutus__img{
        width: 642px;
        // border: 1px solid red;
        img{
          max-width: 610px;
          max-height: 564px;

          @include laptop(){
            max-width: 350px;
          max-height: 300px;
          }
          @include mobile(){
            display: none;
          }
        }
      }
      .aboutus__content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 550px;
        // gap: 25px; 
        @include mobile(){
          width: 345px;
          margin: auto;
        }

        h1{
          font-size: 4.2rem;
          font-weight: 500;
          color:#256FB4;
          @include mobile(){
            font-size: 3.2rem;
            margin:   auto;
            margin-bottom: 1.5rem;
          }
        }

       

        p{
          // margin-top: 3.6rem;
          font-size: 1.6rem;
          color:$palletecolor5;
          text-align: justify;

          @include mobile(){
            font-size: 1.3rem;
          }
        }
        .para2{
          margin-top: 20px;
        }

        .blurb_modules{
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 28px;
          @include mobile(){
            gap: 20px;
            margin-top: 40px;
          }

        }
        .blurb_container{
          width: 398px;
          @include mobile(){
            width: 100%;
          }
        }
        .blurb_head{
          display: flex;
          gap: 20px;
        }
        .blurb_head h4{
          font-size: 24px;
          font-weight: 500;
          @include mobile(){
            font-size: 20px;
          }
        }
        .blurb_head img{
          height: 40px;
        }

        .blurb_content p{
          font-size: 13px;
          margin-left: 65px;
        }
        button{
          font-size: 1.6rem;
          margin-top: 5.5rem;
          padding: 1.6rem 3.2rem;
          border: 1px solid #713C7D;
          border-radius: 0.4rem;
          background-color:#ffff ;
          color:#713C7D ;//$color3
          cursor: pointer;
          &:hover{
            background-color: #713C7D;//$color1
            color: $color3;
            background-image:none
          }
          &:active{
            background-color:#713C7D ;//$color5
            background-image:none
          }
      }
      }
    }


    .capabilities{
      display: flex;
      flex-direction: column;
      // border: 1px solid red;
      // background-image: url(../src/assets/new/Subtract.jpg);
      overflow: hidden;
      max-width: 1080px;
      margin: auto;
      align-items: center;
      padding-top: 13rem;
      padding-bottom: 15rem;
      @include mobile(){
        padding-top: 7rem;
        padding-bottom: 3rem;
        width: 370px;
      }
      &>h1{
        font-size: 4rem;
        color: #256FB4  ;
          span{
            color: $palletecolor1;
          }
          @include mobile(){
            font-size: 3rem;
            
          }
      }
      &>p{
        font-size: 16px;
        margin-top: 16px;
        text-align: center;
        @include mobile(){
          font-size: 14px;
          width: 360px;
          
        }
      }

      .capabilities_row{
        display: flex;
        margin-top: 72px;

        @include mobile(){
          flex-direction: column;
        }

        
        
        &>.left_col{
          // background-color: #0166A0;
          width: 340px;
          color: rgb(27, 27, 27); 
          text-align: right;
          display: flex;
          flex-direction: column;
          gap: 92px;
          @include mobile(){
            gap: 20px;
            margin-bottom: 20px; 
            text-align: center;
          }
                 
        }
        &>.center_col{
          width: 430px;
          height: 394px;
          // background-color: #66a7cc;
          color: white;  
          @include mobile(){
            display: none;
          }
          
        }
        &>.right_col{
          width: 340px;
          // background-color: #18313f;
          color: rgb(27, 27, 27); 
          display: flex;
          flex-direction: column;
          gap: 92px;  
          @include mobile(){
            text-align: center;
            gap: 20px;
          }
          
        }
      }

      .capabilities_row .blurb{
        @include mobile(){
          box-shadow: 0px 0px 10px 2px rgb(214, 214, 214);
          padding: 30px;
          border-radius: 10px;
        }
        &>h4{
          font-size: 16px;
        }
        &>p{
          font-size: 13px;
        }
      }

      .left_col .center_blurb{
        margin-right:46px ;
        @include mobile(){
          margin-right: 0px;
        }
      }
      .left_col .ltr{
        margin-right: -30px;
        z-index: 10;
        margin-top: 20px;
        @include mobile(){
          margin-right: 0px;
          margin-top: 0px;
        }
      }
      .right_col .center_blurb{
        margin-left:46px ;
        @include mobile(){
          margin-left: 0px;
        }
        
      }
      .right_col .rtl{
        margin-left: -25px;
        @include mobile(){
          margin-left: 0px;
        }
      }
      .center_col{
        background-image: url(../src/assets/new/services.jpg);
      }
    
    }

    .differences{
      display: flex;
      flex-direction: column;
      // border: 1px solid red;
      background-image: url(../src/assets/new/Subtract.jpg);
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      overflow: hidden;
      margin: auto;
      align-items: center;
      padding-top: 13rem;
      padding-bottom: 15rem;
      @include mobile(){
        padding-top: 22.2rem;
      }
      &>h1{
        font-size: 4rem;
        color: #256FB4  ;
          span{
            color: $palletecolor1;
          }
          @include mobile(){
            font-size: 2.6rem;
          }
      }

      .servicesparent{
        display: flex;
        gap: 2rem;
        margin-top: 5.6rem;
        @include mobile(){
          flex-direction: column;
          width: 360px;
        }
        .services__card{
          // border: 1.5px solid transparent;
          max-width: 38.4rem;
          min-height: 40rem;
          background-color: white;
          box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
          padding: 5.2rem 2.6rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 0.8rem;
          @include mobile(){
            padding: 2.5rem 2.6rem;
          }
          h1{
            font-size: 2.4rem;
            margin-top: 2.4rem;
            color: $palletecolor6;
            text-align: center;
            
          }
          p{
            font-size: 1.6rem;
            margin-top: 1.6rem;
            color: $palletecolor2;
            text-align: center;
            // white-space: nowrap;
          }

          &:hover{
            // box-shadow: 0 40px 46px 0 rgba(0, 0, 0, 0.08);
            // border: 1.5px solid #256FB4;
          }
        }

      }
    }
      
    .footerContainer{
      margin-top: 132px;
      overflow: hidden;
      padding-top:5.4rem;
      padding-bottom: 4.7rem;
      background-color:white;
      border-top: 1px solid #EFEFEF;
    .footer {
      // border: 5px solid blue;
      max-width: 1440px;
      margin: auto;
      padding: 0 100px;

      @include mobile() {
        padding: 0 25px;

      }
      .footerContent {
        // flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border: 1px solid red;
        @include mobile(){
          flex-direction: column;
          gap: 50px;
          align-items: flex-start;
        }
        .about {
          // border: 1px solid red;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          max-width: 39.7rem;
          p {
            margin-top: 30px;
            // text-align: justify;
            font-size: 1.6rem;
            width: 498px;
            @include mobile(){
              width: 340px;
            }
          }
          .icons {
            margin-top: 2rem;
            display: flex;
            gap: 11px;
            & > div {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              width: 40px;
              border-radius: 50%;
              background-color: #DDECF4;
              color: white;
              &>a{
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
              }

             
              &:hover{
                cursor: pointer;
                background-color: #256FB4;
              }
              &>a>*{
                  width: 1.6rem;
                  height: 1.6rem;
                  
              }
             
            }

            @include mobile(){
              margin-top: 3rem;
            }
          }
        }
    
        .quickmenu {
          // border: 1px solid red;
          h1 {
            font-weight: 600;
            font-size: 2.4rem;
          }
    
          display: flex;
          flex-direction: column;
          gap: 16px;
          // color: $color3;
          @include mobile(){
            
            gap: 10px;
            
          }
    
          div {
            cursor: pointer;
            font-size: 1.6rem;
            &:hover{
              color: #256FB4;
            }
          }
        }
    
        .contact {
          // border: 1px solid red;
          display: flex;
          flex-direction: column;
          gap: 25px;
          color: black;

          @include mobile(){
            
            gap: 12px;
            
          }

          .icon{
              color: #1B8ED1;
                font-size: 22px;
              
          }
          & > div {
            display: flex;
            align-items: center;
            gap: 12px;
            font-size: 1.6rem;
          }
        }
      }
    
    }
 
    }

    .footerbar{

      p{
        @include mobile(){
          width: 360px;
          text-align: center;
          line-height: 1.2em;
        }
      }
     
    }

    main {
      min-height: 100vh;
      display: grid;
      place-items: center;
    }
    .title {
      text-align: center;
      margin-bottom: 4rem;
    }
    .underline {
      height: 0.25rem;
      width: 5rem;
      background: var(--clr-primary-5);
      margin-left: auto;
      margin-right: auto;
    }
    .container {
      width: 80vw;
      max-width: var(--fixed-width);
    }

   
}

.footerbar {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.6rem;
  background-color: #E9E9E9;
  font-size: 1.6rem;
}

.Trusted{
  background-image: url(../src/assets/new/banner.jpg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 350px;
  background-size: cover;
  background-position: center top;
  @include mobile(){
    height: 280px;
  }
 
}
.Trusted h2{
  font-size: 42px;
  color: $color3;
  font-weight: 500;
  @include mobile(){
    font-size: 2.2rem;
    width: 360px;
    text-align: center;
  }
}

.count{
  display: flex;
  justify-content: space-between;
  margin-top: 5.6rem;
  width: 1080px;
  @include mobile(){
    width: 345px;
    margin-top: 3.2rem;
  }
}
.count h4{
  font-size: 4.7rem;
  color: $color3;
  font-weight: 500;
  text-align: center;
  @include mobile(){
    font-size: 2.2rem;
  }
}
.count p{
  font-size: 20px;
  color: white;
  @include mobile(){
    font-size: 14px;
    text-align: center;
  }
}

.pricing-container{
  max-width: 1440px;
  padding: 0 10rem;
  margin: 60px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading h2{
  font-size: 48px;
  font-weight: 600;
  text-align: center;
}
.heading p{
  text-align: center;
  font-size: 16px;
}

.faqs{
  display: flex;
      flex-direction: column;
      // border: 1px solid red;
      overflow: hidden;
      max-width: 834px;
      margin: auto;
      align-items: center;
      @include mobile(){
       
      }

  &>h1{
    font-size: 4rem;
    color: #256FB4  ;
      span{
        color: $palletecolor1;
      }
      @include mobile(){
        font-size: 3rem;
      }
  }
  &>p{
    font-size: 16px;
    margin-top: 16px;
    text-align: center;
    @include mobile(){
      width: 360px;
    }
  }
  &>.faq-text{
    margin-top: 60px;
    @include mobile(){
      width: 360px;
    }
  }

  &>h2{
    margin-top: 56px;
    align-self: flex-start;
    font-size: 20px;
    font-weight: 400;
    font-family: 'poppins';
    @include mobile(){
      margin-left: 25px;
    }
  }
  .szh-accordion{
    margin-top: 16px;
    width: 834px;
    // border: 1px solid red;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include mobile(){
      width: 370px;
    }
  }
  .szh-accordion__item{
    // border: 2px solid blue;
  }
  .szh-accordion__item-heading{
    // border: 2px solid orange;
  }
  .szh-accordion__item-btn{
    background-color: white;
    padding: 16px 22px;
    font-size: 16px;
    font-family: 'poppins';
    color: #5E6362;
    border-radius: 4px;
    border: 1px solid #E8E8E8;
    width: 100%;
    text-align: left;
    cursor: pointer;
    &:hover{
      background-color: rgb(240, 238, 238);
    }
  }

  .szh-accordion__item-content{

  }
  .szh-accordion__item-panel{
    background-color: white;
    padding: 16px 22px;
    font-size: 16px;
    font-family: 'poppins';
    color: #5E6362;
    width: 100%;
    text-align: left;
    border:1px solid #E8E8E8;
    border-top: none;
  }

  .faq-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
  }
  
}


.contactus{
  border-radius: 4px;
  color: black;
  background: white;
  padding: 90px 135px;
  max-width: 104.6rem;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 60px;
  display:flex;
  flex-direction: column;
  border: 1px solid #E4E4E4;


  @include mobile(){
    overflow: hidden;
    padding: 22px 10px;
    // margin: 7.2rem 10px;
    width: 370px;
  }
  
  &>h1{
    font-size: 4rem;
    align-self: center;
    color: #256FB4  ;
      span{
        color: $palletecolor1;
      }
      @include mobile(){
        font-size: 3rem;
      }
  }
  &>p{
    font-size: 16px;
    margin-top: 16px;
    text-align: center;
    @include mobile(){
      font-size: 1.4rem;
    }
  }

  .row{
    display: flex;
    align-items: center;
    width: 100%;
    
    // border: 1px solid red;
    gap: 8px;
    @include mobile(){
      flex-direction: column;
    }
  }
  form{
    // border: 1px solid red;
    margin-top: 5.6rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include mobile(){
      gap: 10px;
    }

  }
  input{
    flex-grow: 1;
    padding: 13px 16px;
    border-radius: 4px;
    border: 1px solid #EDEDED;
    outline: none;
    // box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
    @include mobile(){
      width: 100%;
    }
  }

  textarea{
    padding: 13px 16px;
    border-radius: 4px;
    border: 1px solid #EDEDED;
    outline: none;
    // box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
    resize: none;
    min-height: 14.6rem;
  
  }

  button{
    align-self: flex-end;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
        padding: 12px 16px;
        background-color: #256FB4;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: white;
        &:hover{
          background-color: #256FB4;
          color: white;
          background-image:none
        }


        .loader {
          border: 2px solid #f3f3f3;
          border-radius: 50%;
          border-top: 2px solid #3498db;
          width: 20px;
          height: 20px;
          animation: spin 2s linear infinite;
        }

        @include mobile(){
          white-space: nowrap;
        }
   }

   .Success{
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    gap: 10px;
    color: rgb(44, 102, 44);
    P{
      color:rgb(44, 102, 44);
      font-size: 16px;
    }
   }

   .Error{
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    gap: 10px;
    color:rgb(212, 26, 26);
    P{
      color:rgb(212, 26, 26);
      font-size: 16px;
    }
   }

}